/**
 * Redirects the user to the Shopify discount page with the specified discount code.
 * If `redirect` is provided, sets it as the `redirect` parameter in the URL.
 */
const applyShopifyDiscount = (
  discountCode: string,
  redirect?: string
): void => {
  const params = new URLSearchParams(window.location.search);

  if (redirect) {
    params.set("redirect", redirect);
  }

  // Store discount code in cookies
  document.cookie = `discount_code=${discountCode}; path=/`;

  const url = new URL(`/discount/${discountCode}`, window.location.origin);
  url.search = params.toString();
  const stringUrl = url.toString();

  window.location.href = stringUrl;
};

export { applyShopifyDiscount };
